<template>
    <li class="mb-2">
        <label class="custom-checkbox" v-bind:class="{'disabled': disabled}">
            <input class="uk-checkbox me-2 mt-1" :name="group.id" v-model="checked" @change="$emit('input', checked);" type="checkbox" v-bind:disabled="disabled">
            <div class="d-flex justify-content-between align-items-center">
                <p class="my-0">{{ parseFloat(size.value.replace(",", ".")).toFixed(2) }} {{ group.unit }}</p>
                <p class="my-0 text--small text--grey-dark">({{ size.products_count }})</p>
            </div>
        </label>
    </li>
</template>

<script>
    export default {
        name: "FilterSidebarSizes",
        mounted() {
            
        },
        data() {
            return {
                checked: this.activeSizes[this.size.id] || false,
            }
        },
        methods: {
            
        },
        watch: {
          activeSizes: function(val, oldVal){
            this.checked = val[this.size.id] || false;
          }
        },
        computed: {
          disabled: function(){
            if(this.size.products_count === 0 && !this.checked){
              return true;
            }
            return false;
          }
        },
        props: {
            size: {
                type: Object|Array,
                required: true
            },
            group: {
                type: Object|Array,
                required: true
            },
            defaultChecked: {
                type: Boolean,
                default: false
            },
            activeSizes: {
              type: Object|Array,
              required: false
            }
        }
    }
</script>

<style scoped>
</style>