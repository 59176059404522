var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "select",
    {
      staticClass: "uk-select custom-select",
      attrs: { name: "", id: "" },
      on: {
        change: function ($event) {
          return _vm.getVariation($event, _vm.flavourId)
        },
      },
    },
    _vm._l(_vm.sizes, function (size) {
      return _c(
        "option",
        { domProps: { value: size.id, selected: _vm.activeSize === size.id } },
        [_vm._v(_vm._s(size.value) + " " + _vm._s(size.group.unit_shorthand))]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }