var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    { staticClass: "cart-dropdown__row justify-content-between" },
    [
      _c("div", { staticClass: "cart-dropdown__image__container" }, [
        _c("img", {
          staticClass: "cart-dropdown__image lazyload",
          attrs: { src: _vm.imageUrl },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cart-dropdown__content" }, [
        _c("div", { staticClass: "cart-dropdown__header" }, [
          _c("div", { staticClass: "cart-dropdown__title" }, [
            _vm.item.quantity > 1
              ? _c("span", [_vm._v(_vm._s(_vm.item.quantity) + "x")])
              : _vm._e(),
            _vm._v(" " + _vm._s(_vm.item.product.name || "")),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "cart-dropdown__price" }, [
            _vm._v(
              "€ " +
                _vm._s(
                  _vm.showVat
                    ? _vm.item.formatted.subtotal_min_discount
                    : _vm.item.formatted.subtotal_min_discount_incl_vat
                )
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm.variations !== ""
          ? _c("div", { staticClass: "cart-dropdown__description" }, [
              _c("p", [_vm._v(_vm._s(_vm.variations))]),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }