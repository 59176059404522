<template>
  <button
    @click.prevent="clickable ? $emit('change', activeProduct) : null"
    class="size flavour-image lazyload background"
    :style="style + 'background-image: url(' + image + ')'"
    v-bind:class="{ 'uk-active': activeFlavour === this.flavour.id }"
  ></button>
</template>

<script>
import { isEmpty } from "../functions";
import { EventBus } from "../EventBus";

export default {
  name: "ProductConfiguratorFlavour",
  data() {
    return {
      slug: this.variation?.slug ?? "javascript:void(0)",
      activeProduct: this.variation,
    };
  },
  mounted() {},
  computed: {
    clickable: function () {
      return !isEmpty(this.variation);
    },
    style: function () {
      let style = "";
      if (!this.clickable) {
        style += "cursor: no-drop;";
      }

      return style;
    },
    image() {
      return (
        this.activeProduct?.images[0]?.original ??
        "/dist/assets/images/image_placeholder.png"
      );
    },
  },
  watch: {
    variation(newValue) {
      this.activeProduct = Object.assign({}, newValue);
    },
  },
  props: {
    flavour: {
      type: Object | Array,
      required: true,
    },
    variation: {
      type: Object | null,
      default: null,
      required: true,
    },
    activeSize: {
      type: Number,
      required: false,
      default: null,
    },
    activeFlavour: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>
</style>
