var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.removeFocus,
          expression: "removeFocus",
        },
      ],
      staticClass: "search-bar d-flex justify-content-end",
    },
    [
      _c(
        "form",
        {
          staticClass: "search-bar__search",
          attrs: { id: "search-bar__search" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.handleSearch.apply(null, arguments)
            },
          },
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.q,
                expression: "q",
              },
            ],
            attrs: {
              autocomplete: "off",
              type: "text",
              name: "search",
              placeholder: _vm.translations.placeholder || "",
            },
            domProps: { value: _vm.q },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.q = $event.target.value
                },
                _vm.debounceSearch,
              ],
              focus: function ($event) {
                _vm.focused = true
              },
            },
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              attrs: { type: "submit" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.handleSearch.apply(null, arguments)
                },
              },
            },
            [
              _c("img", {
                attrs: {
                  src: "/dist/assets/images/icons/search.svg",
                  alt: "Search icon",
                  draggable: "false",
                  "uk-svg": "",
                },
              }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ul",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showResults,
              expression: "showResults",
            },
          ],
          staticClass: "search-bar__search-results active",
          attrs: { id: "search-bar__search-results" },
        },
        [
          _vm.isLoading
            ? _c("div", { staticClass: "loader" }, [
                _c("i", { staticClass: "fa-solid fa-circle-notch fa-spin" }),
              ])
            : _vm.q.length > 2 && _vm.totalResults === 0
            ? _c("li", [_vm._v(_vm._s(_vm.translations.no_results))])
            : _vm._e(),
          _vm._v(" "),
          _vm.q.length > 2 && _vm.pwResults.length > 0
            ? _c(
                "li",
                { staticClass: "title" },
                [
                  _c("p", [_vm._v(_vm._s(_vm.translations.pages))]),
                  _vm._v(" "),
                  _vm._l(_vm.pwResults, function (result) {
                    return _c("ul", { staticClass: "uk-list" }, [
                      _c("li", { staticClass: "mt-0" }, [
                        _c(
                          "a",
                          { attrs: { href: result.url, title: result.name } },
                          [
                            _c(
                              "vue-text-highlight",
                              { attrs: { queries: [_vm.q] } },
                              [_vm._v(_vm._s(result.name))]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ])
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.results, function (records, name) {
            return _vm.q.length > 2 &&
              _vm.totalResults > 0 &&
              records.length > 0
              ? _c(
                  "li",
                  { staticClass: "title" },
                  [
                    _c("p", [_vm._v(_vm._s(_vm.translations[name] || name))]),
                    _vm._v(" "),
                    _vm._l(records, function (record) {
                      return _c("ul", { staticClass: "uk-list" }, [
                        _c("li", { staticClass: "mt-0" }, [
                          _c(
                            "a",
                            { attrs: { href: record.url, title: record.name } },
                            [
                              _c(
                                "vue-text-highlight",
                                { attrs: { queries: [_vm.q] } },
                                [_vm._v(_vm._s(record.name))]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ])
                    }),
                  ],
                  2
                )
              : _vm._e()
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }