var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("button", {
    staticClass: "size flavour-image lazyload background",
    class: { "uk-active": _vm.activeFlavour === this.flavour.id },
    style: _vm.style + "background-image: url(" + _vm.image + ")",
    on: {
      click: function ($event) {
        $event.preventDefault()
        _vm.clickable ? _vm.$emit("change", _vm.activeProduct) : null
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }