var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.cart !== null
    ? _c("div", { staticClass: "dropdown-item" }, [
        _c(
          "a",
          { staticClass: "cart-button", attrs: { href: _vm.routes.overview } },
          [_c("i", { staticClass: "fa-regular fa-cart-shopping" })]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "cart-dropdown",
            attrs: { "uk-dropdown": "pos: bottom-right" },
          },
          [
            _c("h2", { staticClass: "mt-0" }, [
              _vm._v(_vm._s(_vm.translations.title || "")),
            ]),
            _vm._v(" "),
            _vm.cart.items.length
              ? _c(
                  "div",
                  [
                    _vm._l(_vm.cart.items, function (item) {
                      return _c("cart-dropdown-item", {
                        key: item.id,
                        attrs: {
                          "init-item": item,
                          translations: _vm.translations,
                          href: item.product.slug || "#",
                          "show-vat": _vm.showVat,
                        },
                      })
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-end align-items-baseline mt-3",
                      },
                      [
                        _c("p", { staticClass: "my-0 me-3" }, [
                          _vm._v(_vm._s(_vm.translations.subtotal)),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "my-0" }, [
                          _c("strong", [
                            _vm._v(
                              "€ " +
                                _vm._s(
                                  _vm.showVat
                                    ? _vm.cart.formatted
                                        .subtotal_min_product_discount
                                    : _vm.cart.formatted
                                        .subtotal_min_product_discount_incl_vat
                                )
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-end mt-3" },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "uk-button uk-button-primary",
                            attrs: { href: _vm.routes.overview },
                          },
                          [_vm._v(_vm._s(_vm.translations.to_overview))]
                        ),
                      ]
                    ),
                  ],
                  2
                )
              : _c("div", [
                  _c("h4", [_vm._v(_vm._s(_vm.translations.empty))]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "uk-button uk-button-primary",
                      attrs: { href: _vm.routes.shop_page },
                    },
                    [_vm._v(_vm._s(_vm.translations.shop_now))]
                  ),
                ]),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }