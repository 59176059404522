var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mb-md-0 mb-0" }, [
    _c(
      "a",
      {
        staticClass: "product",
        attrs: { href: _vm.product.slug, title: _vm.product.name },
      },
      [
        _c(
          "span",
          {
            staticClass:
              "product__header lazyload background uk-position-relative",
            attrs: { "data-bgset": _vm.imageUrl },
          },
          [
            !_vm.hidePrices
              ? _c(
                  "button",
                  {
                    staticClass: "product__cart uk-position-absolute",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.cartClickHandler()
                      },
                    },
                  },
                  [
                    !_vm.isLoading
                      ? _c("i", {
                          staticClass: "fa-solid fa-cart-shopping",
                          attrs: { width: "30" },
                        })
                      : _c("div", { staticClass: "loader" }, [
                          _c("i", {
                            staticClass: "fa-solid fa-circle-notch fa-spin",
                          }),
                        ]),
                  ]
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c("span", [
          _c("div", { staticClass: "product__info" }, [
            _c("p", { staticClass: "product__title" }, [
              _vm._v(_vm._s(_vm.product.name)),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "product__footer" }, [
            _c("div", [
              !_vm.hidePrices
                ? _c(
                    "div",
                    { staticClass: "product__price mb-2 d-flex flex-column" },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "product__price--current product__price--current-card",
                        },
                        [_vm._v("€ " + _vm._s(_vm.product.formatted.use_price))]
                      ),
                      _vm._v(" "),
                      _vm.product.discount_active
                        ? _c("p", { staticClass: "product__price--old " }, [
                            _vm._v("€ " + _vm._s(_vm.product.formatted.price)),
                          ])
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }