var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "uk-child-width-1-3@m uk-child-width-1-2 mt-4",
          attrs: {
            "uk-grid": "",
            "uk-scrollspy":
              "target: > div; cls: uk-animation-slide-bottom-small; delay: 30",
          },
        },
        _vm._l(_vm.products, function (product) {
          return _c("product-card", {
            key: product.id,
            attrs: {
              product: product,
              routes: _vm.routes,
              translations: _vm.translations,
              "hide-prices": _vm.hidePrices,
            },
          })
        }),
        1
      ),
      _vm._v(" "),
      _vm.pagination.lastPage > 1
        ? _c("product-overview-pagination", {
            attrs: {
              pagination: _vm.pagination,
              translations: _vm.translations,
              routes: _vm.routes,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }