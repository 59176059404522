<template>
	<select name="" id="" @change="getVariation($event, flavourId)" class="uk-select custom-select">
		<option :value="size.id" :selected="activeSize === size.id" v-for="size in sizes">{{ size.value }} {{ size.group.unit_shorthand }}</option>
	</select>
	<!-- <button @click.prevnet="clickable ? $emit('change', variation) : null" class="size" :style="style"
			v-bind:class="{'uk-active': activeSize == this.size.id}">{{ value }}
	</button> -->
</template>

<script>
import {isEmpty} from "../functions";
import {EventBus} from "../EventBus";

import collect from "collect.js";

export default {
	name: "ProductConfiguratorSize",
	data() {
		return {
			slug: this.variation?.slug ?? 'javascript:void(0)',
			variation:'',
			size_id:this.variation?.sizeId,
		}
	},
	computed: {
		// value: function () {
		// 	let value = this.size.value;

		// 	if (this.size?.group?.unit_shorthand != null) {
		// 		value += " " + this.size.group.unit_shorthand;
		// 	} else if (this.size?.group?.unit != null) {
		// 		value += " " + this.size.group.unit;
		// 	}

		// 	return value;
		// },
		clickable: function () {
			return !isEmpty(this.variation);
		},
		style: function () {
			let style = '';
			if (!this.clickable) {
				style += 'cursor: no-drop;';
			}

			return style;
		},
		
		

	},
	mounted: function getVariation( sizeId, flavourId) {
			this.variation = collect(this.siblings)
				.where('size_id', sizeId)
				.where("flavour_id", flavourId)
				.first();
	},
	methods:{
		getVariation( sizeId, flavourId) {
			this.variation = collect(this.siblings)
				.where('size_id', parseInt(sizeId.target.value))
				.where("flavour_id", flavourId)
				.first();
			this.size_id = parseInt(sizeId.target.value);
			this.handleChange(this.variation);
			EventBus.$emit('SIZE_CHANGED', this.size_id);
			EventBus.$emit('PRODUCT_CHANGED', this.variation);
		},
		handleChange(variation) {
			if (this.redirect) {
				window.location.href = variation.slug;
			} else {
				this.setActive(variation);
				this.addToHistory(variation);
			}
		},
		setActive(variation) {
			this.activeProduct = variation;
			this.flavourId = variation?.flavour_id
		},
		addToHistory(product) {
			if(this.allowHistory) {
				window.history.pushState({product: product}, product.name, window.location.origin + product.slug);
			}
		},
	},
	props: {
		sizes: {
			type: Object | Array,
			required: true
		},
		colorId: {
			type: Object | Array,
			required: true
		},
		sizeId: {
			type: Object | Array,
			required: true
		},
		flavourId: {
			type: Object | Array,
			required: true
		},
		siblings: {
			type: Object | Array,
			required: true
		},
		redirect: {
			type: Boolean,
			required: false,
			default: false,
		},
		allowHistory: {
			type: Boolean,
			required: false,
			default: true,
		},
		activeSize: {
			type: Number,
			required: false,
			default: null
		},
		activeFlavour: {
			type: Number,
			required: true
		}





		// size: {
		// 	type: Object | Array,
		// 	required: true
		// },
		// variation: {
		// 	type: Object | null,
		// 	default: null,
		// 	required: true
		// },
		// activeSize: {
		// 	type: Number,
		// 	required: true
		// },
		// activeColor: {
		// 	type: Number,
		// 	required: false,
		// 	default: null
		// }
	}
}
</script>

<style scoped>

</style>
