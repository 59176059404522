<template>
  <ul class="check-list my-4">
    <li v-for="trump in this.activeProduct.trumps">
      <i class="fa-solid fa-check text--primary me-2 text--small"></i>
      {{ trump["text"] }}
    </li>
  </ul>
</template>

<script>
import { EventBus } from "../EventBus";
import ProductOverviewPagination from "./ProductOverviewPagination";
import * as functions from "../functions.js";

export default {
  name: "ProductChecklist",
  components: { ProductOverviewPagination },
  mounted() {
    EventBus.$on("PRODUCT_CHANGED", (product) => {
      this.activeProduct = product;
      //   this.handleChange(product);
    });
  },
  data() {
    return {
      activeProduct: this.product,
    };
  },
  methods: {},
  props: {
    product: {
      type: Object | Array,
      required: true,
    },
  },
};
</script>

<style scoped>
</style>