<template>
  <a :href="routes.overview" >
    <!-- <span style="position:absolute" class="amount">{{ count }}</span> -->
    <i class="fa-regular fa-heart"></i>
  </a>
</template>

<script>
import { EventBus } from "../EventBus";

export default {
  name: "WishlistIcon",
  mounted() {
    EventBus.$on("WISHLIST_UPDATE", (wishlist) => {
      this.count = wishlist.productsCount;
    });
  },
  data() {
    return {
      count: this.initCount ?? 0,
    };
  },
  props: {
    translations: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    initCount: String | Number,
    routes: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
</style>