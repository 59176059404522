<template>
  <div class="newsletter mb-sm-4 mb-4" ref="formSection">
    <form class="newsletter_form" ref="form">
      <input
        type="text"
        v-model="url"
        name="url"
        v-if="!hideForm"
        class="uk-input d-none"
      />

      <input
        type="text"
        v-model="email"
        name="email"
        class="uk-input"
        :placeholder="translations.email"
        v-if="!hideForm"
      />
      <button
        type="submit"
        @click.prevent="handleSignup"
        class="uk-button uk-button-primary mb-sm-0 mb-4"
        v-if="!hideForm"
      >
        <i class="fa-solid fa-arrow-right"></i>
      </button>
    </form>
    <div  :class="success ? 'input-success' :'input-errors' " v-if="messages.length > 0">
      <p v-for="message in messages" class="uk-text-center">{{ message.message }}</p>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../EventBus";

export default {
  name: "NewsletterForm",
  mounted() {},
  data() {
    return {
      email: "",
      emailErrors: {},
      errors: {},
      url: "",
      messages: {},
      success: false,
      hideForm: false,
    };
  },
  methods: {
    handleSignup() {
      let data = new FormData(this.$refs.form);
      data.append("js_url", "js_set");
      data.append("action", "newsletter");
      data.append("controller", "forms");

      if (this.url == "") {
        window.axios
          .post(this.routes.form_handle, data, {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              "Content-type": "application/x-www-form-urlencoded",
            },
          })
          .then(this.handleRes);
      }
    },
    handleRes(res) {
      this.errors = res?.data?.errorFields ?? {};
      this.messages = res?.data?.messages ?? {};

      if (res?.data?.hideForm) {
        this.hideForm = true;
      }
      if (res?.data?.success) {
        this.success = true;
      }

      if (res?.data?.toTop) {
        this.$refs.formSection.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    },
  },
  props: {
    translations: {
      type: Object | Array,
      required: true,
    },
    routes: {
      type: Object | Array,
      required: true,
    },
  },
};
</script>

<style scoped>
</style>