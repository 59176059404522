<template>
	<div>
		<h1 class="uk-h2">{{activeProduct.name}}</h1>
		<div v-if="!hidePrices" class="d-flex flex-sm-row flex-column align-items-baseline">
			<h2 class="my-0 me-3 product__price--current new-price">€ {{ activeProduct.use_price }}</h2>
			<h5 class="my-0 me-3 product__price--old old-price" v-if="activeProduct.use_price != activeProduct.price"><s>€ {{ activeProduct.price }}</s></h5>
		</div>

		<product-delivery-time
			v-if="activeProduct.delivery_time"
			:delivery-time="activeProduct.delivery_time"
			class="mt-3 mb-4"
		></product-delivery-time>

		<section class="mb-4 mt-5" v-if="flavours && flavours.length > 1">
			<div class="d-flex align-items-baseline mb-2">
				<p class="my-0 me-4 flavour-picker--title">{{ translations.flavour }}</p>
			</div>

			<div class="flavour-picker flavour-picker--wide">
				<product-configurator-flavour
					v-for="flavour in flavours"
					:flavour="flavour"
					:flavours="flavours"
					:variation="getVariation(colorId, sizeId, flavour.id)"
					:active-size="sizeId"
					:active-color="colorId"
					:active-flavour="flavourId"
					:key="flavour.id"
					@change="handleChange"
				></product-configurator-flavour>
			</div>
		</section>

    <section class="mb-4 mt-5" v-if="activeSizes && activeSizes.length > 1">
			<div class="size-picker">
				<div class="d-flex align-items-baseline mb-2">
					<p class="my-0 me-4 size-picker--title">{{ translations.size }}</p>
				</div>

				<div class="sizes">
					<product-configurator-size
              			:sizes="activeSizes"
						:colorId="colorId"
              			:flavourId="flavourId"
              			:sizeId="sizeId"
              			:siblings="siblings"
              			:active-size="sizeId"
						:active-color="colorId"
						:active-flavour="flavourId"
					></product-configurator-size>
				</div>
			</div>
		</section>
		<div v-if="!hidePrices" class="cart-container mt-sm-5 flex-column flex-lg-row align-items-start align-items-lg-center">
			<div class="d-flex cart-container-button" >
				<div class="cart-container-form">
					<form class="form-custom">
						<input type="number" name="amount" class="uk-input uk-text-center" v-model="quantity" min="1" />
					</form>
				</div>
				<div class="d-flex">
					<button class="uk-button uk-button-cart uk-button-primary" @click.prevent="addToCart">
						<i class="fa-solid fa-cart-shopping me-2 pb-1" width="20" v-if="!isLoading"></i>
						<div class="loader" v-else><i class="fa-solid fa-circle-notch fa-spin me-2"></i></div>
						{{ translations.in_my_cart }}
					</button>
				</div>
			</div>
			<wishlist-button
				:in-wishlist="activeProduct.in_wishlist"
				:product-id="activeProduct.id"
				:routes="routes"
      		></wishlist-button>
		</div>
	</div>
</template>

<script>
import {EventBus} from "../EventBus";
import ProductConfiguratorColor from "./ProductConfiguratorColor";
import ProductConfiguratorSize from "./ProductConfiguratorSize";
import collect from "collect.js";
import ProductDeliveryTime from "./ProductDeliveryTime";

export default {
	name: "ProductConfigurator",
	components: {ProductDeliveryTime, ProductConfiguratorSize, ProductConfiguratorColor},
	mounted() {
		if(this.allowHistory) {
			window.history.replaceState({product: this.product}, this.product.name, window.location.origin + this.product.slug);
		}

		window.onpopstate = e => {
			if (e.state?.product) {
				this.setActive(e.state.product);
			}
		}
      EventBus.$on('PRODUCT_CHANGED', product => {
          this.activeProduct = product;
		//   this.handleChange(product);
      });
      EventBus.$on('SIZE_CHANGED', size => {
          this.sizeId = size;
      });
	  let currentSizes = [];
			this.siblings.forEach((item) => {
				if(item.flavour_id == this.activeProduct.flavour_id) {
  					this.sizes.forEach((size) => {
						if(size.id == item.size_id) {
							currentSizes.push(size)
						}
					})
				}
				
			})
			
			this.activeSizes = currentSizes.sort(this.compare);
  
	},
	data(){
		return {
			activeProduct: this.product,
			quantity: 1,
			isLoading: false,
			colorId: this.product?.color_id,
			sizeId: this.product?.size_id,
			flavourId: this.product?.flavour_id,
			activeSizes: this.sizes
		}
	},
	computed: {
		discountLabel: function() {
			let label = this.translations.you_save;

		},
		sizeGroup() {
			return this?.activeProduct?.size_group ?? this?.basart?.size_group ?? null;
		},
		discountMinPiecesLabel: function() {

		}
	},
	methods: {
		handleChange(variation) {
			if (this.redirect) {
				window.location.href = variation.slug;
			} else {
				this.setActive(variation);
				this.addToHistory(variation);
				this.getSizes();

			}
		},
		setActive(variation) {
			this.activeProduct = variation;
			this.colorId = variation?.color_id;
			this.sizeId = variation?.size_id;
			this.flavourId = variation?.flavour_id
			EventBus.$emit('PRODUCT_CHANGED', variation);
		},
		addToHistory(product) {
			if(this.allowHistory) {
				window.history.pushState({product: product}, product.name, window.location.origin + product.slug);
			}
		},
		getSizes(){
			let currentSizes = [];
			this.siblings.forEach((item) => {
				if(item.flavour_id == this.activeProduct.flavour_id) {
  					this.sizes.forEach((size) => {
						if(size.id == item.size_id) {
							currentSizes.push(size)
						}
					})
				}
				
			})
			
			this.activeSizes = currentSizes.sort(this.compare);
			
		},
		compare (a, b) {
        	return parseFloat(a.value) - parseFloat(b.value).toFixed(2)
        },
		getVariation(colorId, sizeId, flavourId) {
			return collect(this.siblings)
				.where('color_id', colorId)
				.where('size_id', sizeId)
				.where("flavour_id", flavourId)
				.first()
				
		},
		addToCart(){
			this.isLoading = true;

			let quantity = this.quantity;
			if(quantity < 1 || isNaN(quantity))
			{
				quantity = 1;
			}

			window.axios.post(this.routes.addToCart, {product_id: this.activeProduct.id, quantity: quantity})
				.then(res => {
					EventBus.$emit('CART_UPDATE', res.data.cart);
					EventBus.$emit('PRODUCT_ADDED_MODAL', {product: this.activeProduct.add_product_modal, quantity: quantity});
					EventBus.$emit("CLOSE_PRODUCT_CARD_CONFIGURATOR");
					this.isLoading = false;
				})
				.catch(error => {
					let message = {
						message: '',
						pos: "top-right",
						status: "danger",
						timeout: 3000,
					};

					EventBus.$emit('NOTIFY', message);
					this.isLoading = false;
				});

		}
	},
	watch: {
		quantity: function(val, oldVal){
			if(val < 1 && val != "")
			{
				this.quantity = 1;
			}
		}
	},
	props: {
		product: {
			type: Object|Array,
			required: true
		},
		basart: {
			type: Object|Array,
			required: false,
			default: null,
		},
		routes: {
			type: Object|Array,
			required: true
		},
		siblings: {
			type: Object|Array,
			required: false,
			default() { return [] }
		},
		colors: {
			type: Object|Array,
			required: false,
			default() { return [] }
		},
		sizes: {
			type: Object|Array,
			required: false,
			default() { return [] }
		},
		flavours: {
			type: Object|Array,
			required: false,
			default() { return [] }
		},
		translations: {
			type: Object|Array,
			required: true
		},
		redirect: {
			type: Boolean,
			required: false,
			default: false,
		},
		allowHistory: {
			type: Boolean,
			required: false,
			default: true,
		},
		hidePrices: {
			type: Boolean,
			required: false,
			default: false,
		}
	}
}
</script>

<style scoped>

</style>
