var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", { staticClass: "uk-table table-border uk-table-small" }, [
    _c(
      "tbody",
      _vm._l(this.activeProduct.specifications, function (specs) {
        return _c("tr", [
          _c("td", { staticClass: "d-flex align-items-center ps-0" }, [
            _c("i", {
              staticClass: "fa-solid fa-circle-info me-2 text--primary",
              attrs: { "uk-tooltip": specs[0]["filter_name"] },
            }),
            _vm._v(" "),
            _c("p", { staticClass: "my-0" }, [
              _vm._v(_vm._s(specs[0]["filter_name"])),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "td",
            { staticClass: "pe-0 uk-text-right" },
            _vm._l(specs, function (spec) {
              return _c("span", [
                _vm._v(" " + _vm._s(spec["name"]) + " "),
                _c("br"),
              ])
            }),
            0
          ),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }