<template>
	<a @click.prevent="clickable ? $emit('change', variation) : null" :href="slug" :style="style" :uk-tooltip="color.name" v-bind:class="{ active: activeColor==color.id}"></a>
</template>

<script>
import {isEmpty} from "../functions";

export default {
	name: "ProductConfiguratorColor",
	data(){
		return {
			slug: this.variation?.slug ?? 'javascript:void(0)',
		}
	},
	computed:{
		clickable: function(){
			return !isEmpty(this.variation);
		},
		style: function(){c
			let style = 'background-color:' + this.color.hex + ";";
			if(!this.clickable){
				style += 'cursor: no-drop;';
			}

			return style;
		}
	},
	props: {
		color: {
			type: Object|Array,
			required: true
		},
		variation: {
			type: Object|null,
			default: null,
			required: true
		},
		activeSize: {
			type: Number,
			required: false,
			default: null
		},
		activeColor: {
			type: Number,
			required: true
		}
	}
}
</script>

<style scoped>

</style>
