var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass:
        "d-flex flex-sm-row flex-column justify-content-md-end justify-content-between align-items-sm-center",
    },
    [
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.sort,
              expression: "sort",
            },
          ],
          staticClass: "custom-select uk-width-medium@m uk-width-large@s",
          attrs: { name: "sorting" },
          on: {
            change: [
              function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.sort = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
              _vm.handleChange,
            ],
          },
        },
        [
          _c("option", { attrs: { value: "" } }, [
            _vm._v(_vm._s(_vm.translations.sort.relevance)),
          ]),
          _vm._v(" "),
          _c("option", { attrs: { value: "name_asc" } }, [
            _vm._v(_vm._s(_vm.translations.sort.name_asc)),
          ]),
          _vm._v(" "),
          _c("option", { attrs: { value: "name_desc" } }, [
            _vm._v(_vm._s(_vm.translations.sort.name_desc)),
          ]),
          _vm._v(" "),
          _c("option", { attrs: { value: "price_asc" } }, [
            _vm._v(_vm._s(_vm.translations.sort.price_asc)),
          ]),
          _vm._v(" "),
          _c("option", { attrs: { value: "price_desc" } }, [
            _vm._v(_vm._s(_vm.translations.sort.price_desc)),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }