var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "check-list my-4" },
    _vm._l(this.activeProduct.trumps, function (trump) {
      return _c("li", [
        _c("i", {
          staticClass: "fa-solid fa-check text--primary me-2 text--small",
        }),
        _vm._v("\n    " + _vm._s(trump["text"]) + "\n  "),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }