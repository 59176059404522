<template>
    <div id="cart" class="cart" v-if="cart !== null">
        <div v-if="cart.items.length">
            <div v-if="alertText" class="uk-alert-danger" uk-alert>
                <a class="uk-alert-close" uk-close></a>
                <p>{{ alertText }}</p>
            </div>

            <cart-overview-item
                :translations="translations"
                v-for="item in cart.items"
                v-on:update-item="updateProduct"
                v-on:delete-item="deleteProduct"
                :init-item="item"
                :key="item.id"
                :show-vat="showVat"
                :route="item.product.slug || '#'"
                :highlight-stock="stockFailed"
            >
            </cart-overview-item>

            <div class="cart__footer row">
                <div class="col-md-5 offset-md-1 col-sm-12 mb-md-0 mb-4">
                    <div class="d-flex flex-column justify-content-end mb-2">
                        <div class="d-flex align-items-baseline me-sm-4 mb-2">
                            <p class="my-0 cart-labels">{{ translations.shipping_pickup || '' }}</p>
                        </div>

                        <select class="custom-select" v-model="selectedDeliveryMethod" @change="updateDeliveryMethod" name="delivery">
                            <option :value="null" selected disabled>{{ translations.choose_pickup || 'kies een optie' }}</option>
                            <option :value="method" v-for="(name, method) in deliveryMethods">{{ name }}</option>
                        </select>
                    </div>

                    <div v-if="errors.delivery_method" class="d-flex justify-content-sm-end align-items-center mb-3">
                        <i class="fa-solid fa-exclamation-triangle me-3 uk-text-emphasis uk-text-danger"></i>
                        <p class="my-0 uk-text-small uk-text-danger">
                          {{ errors.delivery_method[0] }}
                        </p>
                    </div>

                    <div class="d-flex flex-column justify-content-end mb-3 mt-3 mt-md-5">
                        <p class=" me-sm-4 mb-2 cart-labels">{{ translations.discount_giftcard }}</p>
                        <div class="coupon">
                            <input v-model="code" type="text" class="coupon__input" :placeholder="translations.enter_code || ''" />
                            <button class="coupon__submit" @click="applyCode">
                                <i class="fa-solid fa-arrow-right"></i>
                            </button>
                        </div>
                    </div>

                    <div v-if="errors.code" class="d-flex coupon__error justify-content-sm-start align-items-center">
                        <i class="fa-solid fa-circle-info me-2"></i>
                        <p class="my-0 uk-text-small">
                            {{ errors.code[0] }}
                        </p>
                    </div>

                    <div class="d-flex justify-content-sm-start align-items-center uk-flex-column">
                        <div v-if="cart.discount_code" class="uk-flex uk-flex-middle">
                            <h5 class="uk-display-inline-block my-0 me-2">{{ translations.discount_code }} '{{ cart.discount_code.code }}' ({{ cart.discount_code.readable }}).</h5>
                            <button class="cart__remove" @click="deleteDiscountCode"><i class="fa-solid fa-xmark"></i></button>
                        </div>

                        <div v-for="giftcard in cart.applied_giftcards" class="uk-flex uk-flex-middle">
                            <h5 class="uk-display-inline-block my-0 me-2">{{ translations.giftcard }} '{{ giftcard.blur }}' (€ {{ giftcard.formatted.saldo }}).</h5>
                            <button class="cart__remove" @click="() => deleteGiftCard(giftcard.id)"><i class="fa-solid fa-xmark"></i></button>
                        </div>
                    </div>
                </div>

                <div class="col-md-5 cart-footer-padding">
                    <div v-if="showVat && !cart.is_intra_community" class="cart__totals">
                        <p class="cart__totals__label">{{ translations.subtotal_excl_vat || '' }}</p>
                        <p class="cart__totals__price">&euro; {{ cart.formatted.subtotal }}</p>

                        <p class="cart__totals__label">{{ translations.vat || '' }}</p>
                        <p class="cart__totals__price">&euro; {{ cart.formatted.vat_without_discount }}</p>

                        <p class="cart__totals__label">{{ translations.subtotal_incl_vat || '' }}</p>
                        <p class="cart__totals__price">&euro; {{ cart.formatted.subtotal_incl_vat }}</p>

                        <p class="cart__totals__label" v-if="cart.discount > 0">{{ translations.discount || '' }}</p>
                        <p class="cart__totals__price" v-if="cart.discount > 0">- &euro; {{ cart.formatted.discount_incl_vat }}</p>

                        <p class="cart__totals__label" v-if="cart.giftcard_reduction > 0">{{ translations.giftcard || '' }}</p>
                        <p class="cart__totals__price" v-if="cart.giftcard_reduction > 0">- &euro; {{ cart.formatted.giftcard_reduction }}</p>

                        <p class="cart__totals__label">{{ translations.shipping_cost || '' }}</p>
                        <p class="cart__totals__price delivery-cost">{{ cart.shipping_cost > 0 ? `&euro; ${cart.formatted.shipping_cost}` : translations.free }}</p>

                        <p class="cart__totals__label total">{{ translations.total_incl_vat }}</p>
                        <p class="cart__totals__price total">&euro; {{ cart.formatted.total }}</p>
                    </div>
                    <div v-else >
                        <div class="cart__totals">
                            <p class="cart__totals__label">{{ cart.is_intra_community ? translations.subtotal_excl_vat : translations.subtotal_incl_vat || '' }}</p>
                            <p class="cart__totals__price">&euro; {{ cart.formatted.subtotal_incl_vat }}</p>

                            <p class="cart__totals__label" v-if="cart.discount > 0">{{ translations.discount || '' }}</p>
                            <p class="cart__totals__price" v-if="cart.discount > 0">- &euro; {{ cart.formatted.discount_incl_vat }}</p>

                            <p class="cart__totals__label" v-if="cart.giftcard_reduction > 0">{{ translations.giftcard || '' }}</p>
                            <p class="cart__totals__price" v-if="cart.giftcard_reduction > 0">- &euro; {{ cart.formatted.giftcard_reduction }}</p>

                            <p class="cart__totals__label">{{ translations.shipping_cost || '' }}</p>
                            <p class="cart__totals__price delivery-cost">{{ cart.shipping_cost > 0 ? `&euro; ${cart.formatted.shipping_cost}` : translations.free }}</p>

                           
                        </div>
                        <div class="cart__totals cart__totals-total">
                            <p class="cart__totals__label total">{{ cart.is_intra_community ? translations.total_excl_vat : translations.total_incl_vat }}</p>
                            <p class="cart__totals__price total">&euro; {{ cart.formatted.total }}</p>
                        </div>
                    </div>
                    <div v-if="cart.items.length" class="d-flex justify-content-center justify-content-sm-end align-items-center mt-4">
                        <a :href="routes.continueShopping || '#'" class="uk-button-back uk-button uk-button-tertiary me-4">
                            <i class="fa-solid fa-arrow-left me-2"></i>
                            {{ translations.continue_shopping }}
                        </a>
                        <button v-if="cart.items.length" @click.prevent="validateCart" class="uk-button uk-button-primary">
                          <div class="loader" v-if="validationIsLoading"><i class="fa-solid fa-circle-notch fa-spin me-2"></i></div>
                          {{ translations.continue_order }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="uk-text-center" v-else>
            <h4 class="uk-text-center my-2">{{ translations.empty }}</h4>
            <div class="uk-button-group uk-width-1-2 mx-auto uk-flex uk-flex-wrap uk-flex-around@l">
                <a :href="routes.shop_page" class="uk-button uk-button-primary uk-width-1-1 uk-width-1-3@l uk-text-center mt-3">{{ translations.all_products }}</a>
                <a :href="routes.shop_home" class="uk-button uk-button-secondary uk-width-1-1 uk-width-1-3@l uk-text-center mt-3">{{ translations.back_home }}</a>
            </div>
        </div>
    
        
    </div>
    <div v-else>
        <div class="uk-spinner uk-align-center"></div>
    </div>
</template>

<script>
    import { EventBus } from "../EventBus";

    export default {
        name: "CartOverview",
        mounted() {
            if (!this.initCart) {
                this.fetchCart();
            }

            if (!this.initDeliveryMethods) {
                this.fetchDeliveryMethods();
            }

            

            EventBus.$on('CART_UPDATE', cart => {
                this.cart = cart;
                if (this.stockFailed) {
                    this.stockFailed = !cart.has_all_in_stock;
                }
            });
            this.cart.items.forEach((item) => {
						console.log(item.product.pickup_shop);
					})
        },
        data() {
            return {
                stockFailed: this.stockError,
                alertText: this.initAlertMessage ?? '',
                errors: {},
                code: '',
                cart: this.initCart ?? null,
                deliveryMethods: this.initDeliveryMethods ?? [],
                selectedDeliveryMethod: this?.initCart?.delivery_method_id ?? this?.initCart?.delivery_country?.code ?? null,
                validationIsLoading: false
            }
        },
        methods: {
            setDefaultDeliveryMethod() {
                window.axios.post(this.routes.patch, {delivery_method_id: this.selectedDeliveryMethod, _method: 'PATCH'})
                    .then(this.handleResponse());
            },
            fetchCart() {
                window.axios.get(this.routes.fetchCart)
                    .then(res => {
                        this.handleResponse(res);

                        if (res?.data?.cart) {
                            this.selectedDeliveryMethod = res.data.cart.delivery_method_id ?? res.data.cart?.delivery_country?.code ?? null;
                        }
                    });
            },
            fetchDeliveryMethods() {
                window.axios.get(this.routes.fetchDeliveryMethods)
                    .then(res => {
                        if (res?.data) {
                            this.deliveryMethods = res.data;
                        }
                    });
            },
            updateProduct(id, quantity) {
                const route = this.routes.update.replace('-id-', id);
                window.axios.post(route, {quantity : quantity, _method: 'PUT'})
                    .then(this.handleResponse);
            },
            deleteProduct(id) {
                window.axios.post(this.routes.deleteItem.replace('-id-', id), {_method:'DELETE'})
                    .then(this.handleResponse);
            },
            updateDeliveryMethod() {
                window.axios.post(this.routes.updateDeliveryMethod,{delivery_method: this.selectedDeliveryMethod, _method: 'PUT'})
                    .then(this.handleResponse)
                    .then(() => {
                        this.errors = {};
                    })
                    .catch(err => {
                        this.errors = err?.response?.data ?? {};
                    });
            },
            deleteDiscountCode() {
                window.axios.post(this.routes.deleteDiscountCode, {_method: 'DELETE'})
                    .then(this.handleResponse);
            },
            deleteGiftCard(id) {
                window.axios.post(this.routes.deleteGiftCard.replace('-id-', id), {_method: 'DELETE'})
                    .then(this.handleResponse);
            },
            applyCode() {
                if (this.code.length < 1) {
                    return;
                }

                window.axios.post(this.routes.applyCode,{code: this.code})
                    .then(this.handleResponse)
                    .then(() => {
                        this.errors = {};
                    })
                    .catch(err => {
                        this.errors = err?.response?.data ?? {};
                    })
                    .then(() => {
                        this.code = '';
                    });
            },
            handleResponse(res) {
                if (res?.data?.cart) {
                    EventBus.$emit('CART_UPDATE', res.data.cart);
                }

                if (res?.data?.message) {
                    EventBus.$emit('NOTIFY', res.data.message);
                }

                if (res?.data?.redirect) {
                  window.location.href = res.data.redirect;
                }
            },
            validateCart() {
                this.validationIsLoading = true;
                window.axios.post(this.routes.validateCart, {delivery_method: this.selectedDeliveryMethod})
                    .then(this.handleResponse)
                    .then(() => {
                      this.errors = {};
                    })
                    .catch(err => {
                      this.validationIsLoading = false;
                      this.errors = err?.response?.data ?? {};

                      if (err?.response?.data?.stock_failed) {
                          this.stockFailed = true;
                          delete this.errors.stock_failed;
                      }

                        if (err?.response?.data?.alert_text) {
                            this.alertText = err?.response?.data?.alert_text;
                            delete this.errors.alert_text;
                        }

                      if (err?.response?.data?.cart) {
                          EventBus.$emit('CART_UPDATE', err?.response?.data?.cart);
                          delete this.errors.cart;
                      }

                      Object.values(this.errors).forEach(value => {
                        Object.values(value).forEach(err => {
                          EventBus.$emit('NOTIFY', {message: err, status: 'danger', position: 'top-right', 'timeout': 3000});
                        });
                      });
                    })
            }
        },
        props: {
            routes: {
                type: Object,
                required: true
            },
            translations: {
                type: Object,
                required: true,
                default() { return {} }
            },
            initCart: {
                type: Object,
                required: false
            },
            initDeliveryMethods: {
                type: Object,
                required: false
            },
            showVat: {
                type: Boolean,
                required: false,
                default: false
            },
            stockError: {
                type: Boolean,
                required: false,
                default: false
            },
            initAlertMessage: {
                type: String,
                required: false,
                default: ''
            }
        }
    }
</script>

<style scoped>

</style>