<template>
    <div class="search-bar-mobile d-flex" v-click-outside="removeFocus">
        <form class="search-bar-mobile__search" id="search-bar__search" @submit.prevent="handleSearch">
            <input v-model="q" autocomplete="off" type="text" name="search" :placeholder="translations.placeholder || ''" @input="debounceSearch" @focus="focused = true">
            <button @submit.prevent="handleSearch" type="submit"><img src="/dist/assets/images/icons/search.svg" alt="Search icon" draggable="false" uk-svg></button>
        </form>

        <ul v-show="showResults" class="search-bar-mobile__search-results active" id="search-bar__search-results">
            <div class="loader" v-if="isLoading"><i class="fa-solid fa-circle-notch fa-spin"></i></div>
            <li v-else-if="q.length > 2 && totalResults === 0">{{ translations.no_results }}</li>

            <!-- <li v-if="q.length > 2 && pwResults.length > 0"  class="title">
              <p>{{ translations.pages }}</p>
              <ul v-for="result in pwResults" class="uk-list">
                <li class="mt-0"><a :href="result.url" :title="result.name"><vue-text-highlight :queries="[q]">{{ result.name }}</vue-text-highlight></a></li>
              </ul>
            </li> -->

            <li v-for="(records, name) in results" v-if="q.length > 2 && totalResults > 0 && records.length > 0"  class="title">
                <p>{{ translations[name] || name }}</p>
                <ul v-for="record in records" class="uk-list">
                    <li class="mt-0"><a :href="record.url" :title="record.name"><vue-text-highlight :queries="[q]">{{ record.name }}</vue-text-highlight></a></li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
    import { debounce } from '../functions';
    import collect from "collect.js";
    import VueTextHighlight from 'vue-text-highlight/dist/component';

    export default {
        name: "SearchBarMobile",
        components: {VueTextHighlight},
        data() {
            return {
                q: '',
                results: {},
                pwResults: {},
                focused: false,
                shopsystemLoading: false,
                pwLoading: false,
            }
        },
        created() {
            this.debounceSearch = debounce(this.handleSearch, 200);
        },
        methods: {
            handleSearch() {
                this.focused = true;
                this.shopsystemLoading = true;
                // this.pwLoading = true;
                
                window.axios.get(this.routes.shopsystem, {
                    params: {
                        q: this.q,
                    }
                })
                .then(res => {
                    this.results = res.data;
                    this.shopsystemLoading = false;
                });

                // window.axios.get(this.routes.pw, {
                //   params: {
                //     q: this.q
                //   }
                // }).then(res => {
                //   this.pwResults = res.data;
                //   this.pwLoading = false;
                // });
            },
            removeFocus() {
                this.focused = false;
            }
        },
        computed: {
            isLoading(){
                return this.shopsystemLoading || this.pwLoading;
            },
            showResults() {
                return this.q.length > 2 &&
                    this.focused;
            },
            totalResults() {
                return collect(this.results)
                    .map(val => {
                      return val.length;
                    })
                    .sum();
            }
        },
        props: {
            translations: {
                type: Object,
                required: false,
                default() { return {}; }
            },
            routes: {
                type: Object|Array,
                required: true
            }
        }
    }
</script>

<style scoped>

</style>