var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "formSection", staticClass: "newsletter mb-sm-4 mb-4" },
    [
      _c("form", { ref: "form", staticClass: "newsletter_form" }, [
        !_vm.hideForm
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.url,
                  expression: "url",
                },
              ],
              staticClass: "uk-input d-none",
              attrs: { type: "text", name: "url" },
              domProps: { value: _vm.url },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.url = $event.target.value
                },
              },
            })
          : _vm._e(),
        _vm._v(" "),
        !_vm.hideForm
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.email,
                  expression: "email",
                },
              ],
              staticClass: "uk-input",
              attrs: {
                type: "text",
                name: "email",
                placeholder: _vm.translations.email,
              },
              domProps: { value: _vm.email },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.email = $event.target.value
                },
              },
            })
          : _vm._e(),
        _vm._v(" "),
        !_vm.hideForm
          ? _c(
              "button",
              {
                staticClass: "uk-button uk-button-primary mb-sm-0 mb-4",
                attrs: { type: "submit" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.handleSignup.apply(null, arguments)
                  },
                },
              },
              [_c("i", { staticClass: "fa-solid fa-arrow-right" })]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.messages.length > 0
        ? _c(
            "div",
            { class: _vm.success ? "input-success" : "input-errors" },
            _vm._l(_vm.messages, function (message) {
              return _c("p", { staticClass: "uk-text-center" }, [
                _vm._v(_vm._s(message.message)),
              ])
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }