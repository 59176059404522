var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row cart__container" }, [
    _c("div", { staticClass: "col-md-11 " }, [
      _c("div", { staticClass: "cart__row" }, [
        _c("div", { staticClass: "cga-remove" }, [
          _c(
            "button",
            {
              staticClass: "cart__remove",
              on: {
                click: function ($event) {
                  return _vm.$emit("delete-item", _vm.item.id)
                },
              },
            },
            [_c("i", { staticClass: "fa-solid fa-xmark" })]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cga-link" }, [
          _c("a", { staticClass: "cart__link", attrs: { href: _vm.route } }, [
            _c("div", { staticClass: "cart__image" }, [
              _c("img", {
                staticClass: "lazyload",
                attrs: {
                  "data-src": _vm.imageUrl ? _vm.imageUrl : "",
                  alt: _vm.item.product.name,
                },
              }),
              _vm._v(" "),
              _vm.item.readable
                ? _c("div", { staticClass: "image-banner" }, [
                    _vm._v(_vm._s(_vm.item.readable)),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "cart__product-info" },
              [
                _c("p", { staticClass: "mb-1 uk-h4" }, [
                  _c("strong", [_vm._v(_vm._s(_vm.item.product.name))]),
                ]),
                _vm._v(" "),
                _vm.variations !== "" || _vm.item.product.type === "giftcard"
                  ? _c("p", { staticClass: "mb-1 my-0 text--small" }, [
                      _vm.variations !== ""
                        ? _c("span", { staticClass: "me-2" }, [
                            _vm._v(_vm._s(_vm.variations)),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.item.product.type === "giftcard" && false
                        ? _c(
                            "span",
                            {
                              attrs: {
                                "uk-tooltip":
                                  _vm.translations.giftcard_translations.edit,
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.editGiftcard.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fa-solid fa-pencil edit",
                              }),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.item.product.display_delivery_time
                  ? _c("product-delivery-time", {
                      staticClass: "mb-1",
                      attrs: {
                        "delivery-time": _vm.item.product.display_delivery_time,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.notInStock
                  ? _c(
                      "p",
                      { staticClass: "my-0 text--small uk-text-danger" },
                      [
                        _vm._v(
                          _vm._s(_vm.translations.stock) +
                            ": " +
                            _vm._s(_vm.item.product.stock)
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cga-amount" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.quantity,
                expression: "quantity",
              },
            ],
            staticClass: "cart__amount",
            class: { "cart__amount--danger": _vm.notInStock },
            attrs: {
              type: "number",
              inputmode: "numeric",
              name: "amount",
              min: "1",
            },
            domProps: { value: _vm.quantity },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.quantity = $event.target.value
                },
                _vm.handleUpdate,
              ],
            },
          }),
        ]),
        _vm._v(" "),
        _vm.showVat
          ? _c("div", { staticClass: "cga-price" }, [
              _c("p", { staticClass: "cart__prices--current" }, [
                _vm._v("€ " + _vm._s(_vm.item.formatted.subtotal_min_discount)),
              ]),
              _vm._v(" "),
              _vm.item.total_discount > 0
                ? _c("p", { staticClass: "cart__prices--old" }, [
                    _vm._v("€ " + _vm._s(_vm.item.formatted.subtotal)),
                  ])
                : _vm._e(),
            ])
          : _c("div", { staticClass: "cga-price" }, [
              _c("h3", { staticClass: "cart__prices--current" }, [
                _vm._v(
                  "€ " +
                    _vm._s(_vm.item.formatted.subtotal_min_discount_incl_vat)
                ),
              ]),
              _vm._v(" "),
              _vm.item.total_discount > 0
                ? _c("p", { staticClass: "cart__prices--old" }, [
                    _vm._v("€ " + _vm._s(_vm.item.formatted.subtotal_incl_vat)),
                  ])
                : _vm._e(),
            ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }