var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { class: { "uk-open": _vm.ukOpen } }, [
    _c(
      "a",
      {
        staticClass: "uk-accordion-title",
        attrs: { href: "javascript:void(0)" },
      },
      [
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _vm.sizegroup.description !== null &&
            _vm.sizegroup.description !== ""
              ? _c("info-circle", {
                  attrs: {
                    headline: _vm.sizegroup.name,
                    body: _vm.sizegroup.description,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.sizegroup.name))]),
          ],
          1
        ),
        _vm._v(" "),
        _c("i", { staticClass: "fa-solid fa-chevron-down" }),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "uk-accordion-content" }, [
      _c(
        "ul",
        { staticClass: "sidebar__nav" },
        _vm._l(_vm.sortedSizes, function (size) {
          return _c("filter-sidebar-sizes-group-item", {
            key: size.id,
            attrs: {
              size: size,
              group: _vm.sizegroup,
              "default-checked": _vm.activeSizes[size.id] || false,
              "active-sizes": _vm.activeSizes,
            },
            on: { input: _vm.handleChange },
            model: {
              value: _vm.activeSizes[size.id],
              callback: function ($$v) {
                _vm.$set(_vm.activeSizes, size.id, $$v)
              },
              expression: "activeSizes[size.id]",
            },
          })
        }),
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }