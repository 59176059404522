<template>
    <div class="uk-section uk-section-white contact-form-container" ref="formSection">
        <div class="uk-container">
            <form class="uk-form-stacked form-custom" ref="form">

                <div :class="'uk-alert-' + message.type" uk-alert v-for="message in messages" uk-alert>
                    <a class="uk-alert-close" uk-close></a>
                    <p>{{ message.message }}</p>
                </div>

                <div v-if="!hideForm">
                    <div class="uk-margin" uk-grid>
                        <div class="uk-form-group uk-width-1-1">
                            <label for="name" class="uk-form-label" v-bind:class="{ 'uk-text-danger': errors.name }">{{ translations.name }}</label>
                            <input type="text" class="uk-input uk-form-control" v-bind:class="{ 'uk-form-danger': errors.name }" id="name" name="name" :placeholder="translations.name" v-model="name">
                            <p class="uk-text-danger mt-1" v-if="errors.name" v-for="error in errors.name">{{ error }}</p>
                        </div>
                         <div class="uk-form-group uk-width-1-1 mt-4">
                            <label for="phone" class="uk-form-label" v-bind:class="{ 'uk-text-danger': errors.phone }">{{ translations.phone }}</label>
                            <input type="text" class="uk-input uk-form-control" v-bind:class="{ 'uk-form-danger': errors.phone }" id="phone" name="phone" placeholder="Bv. 0032 475 123 123" v-model="phone">
                            <p class="uk-text-danger mt-1" v-if="errors.phone" v-for="error in errors.phone">{{ error }}</p>
                        </div>
                        <div class="uk-form-group uk-width-1-1 mt-4">
                            <label for="email" class="uk-form-label" v-bind:class="{ 'uk-text-danger': errors.email }">{{ translations.email }}</label>
                            <input type="email" class="uk-input uk-form-control" v-bind:class="{ 'uk-form-danger': errors.email }" id="email" name="email" placeholder="Bv. mail@mail.com" v-model="email">
                            <p class="uk-text-danger mt-1" v-if="errors.email" v-for="error in errors.email">{{ error }}</p>
                        </div>
                        <div class="uk-form-group uk-width-1-1 mt-4">
                            <label for="comments" class="uk-form-label" v-bind:class="{ 'uk-text-danger': errors.comments }">{{ translations.comments }}</label>
                            <textarea name="comments" id="comments" cols="30" rows="4" class="uk-textarea" v-bind:class="{ 'uk-form-danger': errors.comments }" v-model="comments"></textarea>
                            <p class="uk-text-danger mt-1" v-if="errors.comments" v-for="error in errors.comments">{{ error }}</p>
                        </div>
                        <div class="uk-form-group uk-width-1-1 form-url" id="url_wrapper">
                            <label class="uk-form-label" for="url">Url</label>
                            <input class="uk-input uk-form-control" id="url" name="url" type="text" placeholder="Url" v-model="url">
                        </div>
                        <div class="uk-form-group">
                            <label class="uk-form-label-privacy custom-checkbox"  v-bind:class="{ 'uk-form-danger': errors.privacy }" for="privacy"><input class="uk-checkbox" id="privacy" name="privacy" type="checkbox" v-model="privacy">{{ translations.i_agree_with }} {{ company.name }} {{ translations.personal_data }} <a :href="routes.privacy_page" target="_blank" :title="translations.privacy_policy">{{ translations.privacy_policy }}</a></label>
                            <p class="uk-text-danger mt-1" v-if="errors.privacy" v-for="error in errors.privacy">{{ error }}</p>
                        </div>
                    </div>

                    <button class="uk-button uk-button-primary" @click.prevent="handleFormSubmit">{{ translations.submit }}</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ContactForm",
        data() {
            return {
                errors: {},
                name: '',
                email: '',
                phone: '',
                comments: '',
                url: '',
                files: {},
                privacy: false,
                messages: {},
                hideForm: false,
            }
        },
        methods: {
            handleFormSubmit() {

                let data = new FormData(this.$refs.form);
                data.append("js_url", "js_set");
                data.append("action", "contact");
                data.append("controller", "forms");

                window.axios
                    .post(this.routes.form_handle, data, {
                        headers: {
                            'X-Requested-With': 'XMLHttpRequest',
                            'Content-type': 'application/x-www-form-urlencoded',
                        }
                    })
                    .then(this.handleRes);
            },
            handleRes(res){
                this.errors = res?.data?.errorFields ?? {};
                this.messages = res?.data?.messages ?? {};

                if(res?.data?.hideForm)
                {
                    this.hideForm = true;
                }

                if(res?.data?.toTop)
                {
                    this.$refs.formSection.scrollIntoView({behavior: "smooth", block: "start"});
                }
            },
        },
        props: {
            routes: {
                type: Object|Array,
                required: true,
            },
            translations: {
                type: Object|Array,
                required: true
            },
            company: {
                type: Object|Array,
                required: true
            },
        }
    }
</script>

<style scoped>

</style>